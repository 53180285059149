import {autotestAddQestion as addQestion} from '../../../store/queries';
import {mapGetters, mapMutations} from 'vuex';

import {tel as telPattern} from '../../../../constants/patterns';
import {get} from "../../../helpers/cookie";

export default {
	i18n: require('./i18n').default,
	props: ['advertisementId', 'whenNeeded'],
	data() {
		return ({
			errors: [],
			userPhone: '',
			callRegime: '',
			inputUserName: '',
			questionSource: '',
			callOrder: false,
			logDataDefault: { action_block: 8 },
			isTest: false,
		});
	},
	created() {
		this.questionSource = this.$route.path
	},
	computed: {
		isPhoneValid() {
			return telPattern.test(this.userPhone);
		},
		telPatternString() {
			return telPattern.toString().split('/')[1];
		},
		...mapGetters({
			userData: 'Common/userData',
		}),
	},
	watch: {
		userData: {
			handler() {
				if (!this.userPhone && this.userData?.userPhones?.[0]) {
					this.userPhone = this.userData?.userPhones?.[0];
				}

				if (!this.inputUserName && this.userData?.userName) {
					this.inputUserName = this.userData?.userName;
				}
			},
			deep: true
		},
	},
	methods: {
		...mapMutations({
			autotestLoggerData: 'others/autotest/autotestLoggerData',
		}),
		addQestion(event) {
			event.preventDefault();
			this.errors.splice(0, this.errors.length);

			if (!this.isPhoneValid) {
				this.errors.push({message: this.$t('введите правильный номер')});
				return false;
			}

			this.autotestLoggerData({ ...this.logDataDefault, click_action: 2 });

			const {advertisementId, userPhone, callRegime, questionSource, inputUserName, userData: {userId} = {}} = this;
			return addQestion({advertisementId, phone: userPhone, phone_time: callRegime, userId, userName: inputUserName, questionSource: questionSource})
				.catch(console.error)
				.then((response) => this.callOrder = response)
		},
		gdprLog() {
			this.autotestLoggerData({ ...this.logDataDefault, click_action: 1 });
			this._slonik({ event_id: 717, message_id: 1, action_block: 13 });
		}
	},
	mounted() {
		if (get('test_VCWEB-5943') === '1') {
			this.isTest = true;
		}
	},
};
